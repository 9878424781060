<template>
  <v-container
    class="py-0"
  >
    <v-row>
      <v-col
        class="body-1 text-center"
        cols="12"
      >
        <v-icon
          size="78px"
          color="success"
        >
          mdi-credit-card-outline
        </v-icon>

        <h2 class="mt-3">
          {{ $t('contract.bill.Payment_the_invoice') }} id:  [{{ paiementFacture.id }}]
        </h2>

        <h2
          v-if="paiementFacture.montant_total"
          class="mt-3"
        >
          {{ $t('Amount') }} {{ paiementFacture.montant_total.toFixed(2) }}$
        </h2>
      </v-col>
    </v-row>

    <validation-observer
      ref="obsNewCreditCard"
      v-slot="{ handleSubmit }"
    >
      <form>
        <!-- Nom-->
        <v-row
          justify="center"
          dense
        >
          <v-col
            cols="10"
          >
            <validation-provider
              v-slot="{ errors, valid }"
              :name="$t('profile.automatic_payment.HolderName')"
              rules="required"
            >
              <v-text-field
                v-model="cardSelected.name"
                :label="$t('profile.automatic_payment.HolderName')"
                :error-messages="errors"
                :success="valid"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <!-- Numéro de carte-->
        <v-row
          justify="center"
          dense
        >
          <v-col
            cols="10"
          >
            <validation-provider
              v-slot="{ errors, valid }"
              :name="$t('profile.automatic_payment.No_credit_card')"
              :rules="{ required: true, min: 15, max: 16, numeric: true }"
            >
              <v-text-field
                v-model="cardSelected.card.number"
                maxlength="16"
                :label="$t('profile.automatic_payment.No_credit_card')"
                :error-messages="errors"
                :success="valid"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <!-- Expiration-->
        <v-row
          justify="center"
          dense
        >
          <v-col cols="10">
            <v-row
              justify="center"
              dense
            >
              <!-- Mois-->
              <v-col
                cols="6"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('time.month')"
                  :rules="{ required:true, validCardMonth: cardSelected.card.expiry_year }"
                >
                  <v-select
                    v-model="cardSelected.card.expiry_month"
                    :items="cardExpiration.month"
                    :label="$t('time.month')"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>

              <!-- year-->
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('time.year')"
                  rules="required"
                >
                  <v-select
                    v-model="cardSelected.card.expiry_year"
                    :items="cardExpiration.year"
                    :label="$t('time.year')"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- CVV-->
        <v-row
          justify="center"
          dense
        >
          <v-col cols="10">
            <validation-provider
              v-slot="{ errors, valid }"
              name="CVV"
              :rules="{ required: true, min: 3, max: 4, numeric: true }"
            >
              <v-text-field
                v-model="cardSelected.card.cvv"
                maxlength="4"
                label="CVV"
                :error-messages="errors"
                :success="valid"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row
          dense
        >
          <v-col
            offset-md="1"
            cols="12"
          >
            <div class="body-2 red--text">
              *{{ $t('profile.automatic_payment.Prepaid_card_warning') }}
            </div>
          </v-col>
        </v-row>

        <!--  Bouton submit-->
        <div class="text-center">
          <v-btn
            color="success"
            class="ml-3 mt-4"
            @click="handleSubmit(doPayment)"
          >
            {{ $t('Validate') }}
          </v-btn>
        </div>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
  // Service
  import FacturationsService from '@/services/01Cell/FacturationsService'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'GlobalPaymentsFacturePaiement',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      paiementFacture: Object,
    },
    data () {
      return {
        cardSelected: {
          name: null,
          card: {
            number: null,
            expiry_month: null,
            expiry_year: null,
            cvv: null,
          },
        },
        cardExpiration: {
          month: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
          year: [],
        },
      }
    },

    computed: {
    },

    async mounted () {
      // Array pour les années d'expiration pour une nouvelle carte
      const d = new Date()
      const year = d.getFullYear()
      for (let index = 0; index < 7; index++) {
        this.cardExpiration.year.push(year + index)
      }
    },

    methods: {
      async doPayment () {
        // Start Spinner
        this.$loading.show()
        try {
          const facture = await FacturationsService.payerFactureManuelleCard(this.cardSelected, this.paiementFacture.id)

          // On mets à jour la facture
          this.$emit('payer-facture-success', facture)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // On mets à jour la facture
          this.$emit('payer-facture-error', this.paiementFacture.id)
        }
      },
    },
  }
</script>
