<template>
  <v-row>
    <v-col cols="12">
      <validation-observer
        ref="obs"
        v-slot="{ handleSubmit }"
      >
        <form>
          <base-material-card
            inline
            :icon="createEditInfo.icon"
            :title="createEditInfo.title"
            :color="color"
            class="px-5 py-3"
          >
            <!--  Client-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                Client*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Client"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="submitForm.Client"
                    :items="client.entries"
                    :loading="client.isLoading"
                    :search-input.sync="client.search"
                    hide-no-data
                    hide-selected
                    item-text="firstNameName"
                    item-value="id"
                    :placeholder="$t('Start_typing_Search')"
                    append-icon="mdi-account"
                    return-object
                    :error-messages="errors"
                    :success="valid"
                    required
                    @keypress.enter="$event.preventDefault()"
                  >
                    <template #selection="data">
                      {{ data.item.firstNameName }}
                    </template>
                    <template #item="data">
                      {{ data.item.firstNameName }}
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>

            <!--  No de contrat-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('contract.no') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('contract.no')"
                  :rules="{ required:true, min:10, max:10, numeric:true }"
                >
                  <v-text-field
                    v-model="submitForm.no"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Mode de paiement-->
            <v-row
              v-if="submitForm.Client"
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('order.Payment_mode') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.Payment_mode')"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.type"
                    :items="getType"
                    item-text="item"
                    item-value="valeur"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                    @change="resetBillingcode"
                  />
                </validation-provider>
                <small
                  v-if="getTypeMessage"
                  class="red--text"
                >{{ getTypeMessage }}</small>
              </v-col>
            </v-row>

            <!--  Code de Facturation (UBR)-->
            <v-row
              v-if="submitForm.type === 1"
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('contract.Billing_code') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="UBR"
                  :rules="getUbrValidationRules"
                >
                  <v-text-field
                    v-model="submitForm.code_facturation"
                    class="mt-3"
                    :maxlength="parameterLongueurCodeFacturation"
                    :label="$t('order.ubr_description')"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Description-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                Description*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Description"
                  rules="required"
                >
                  <v-textarea
                    v-model="submitForm.description"
                    rows="1"
                    auto-grow
                    append-icon="mdi-comment"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Statut-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('Status') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('Status')"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.statut"
                    :disabled="checkDisabledStatus"
                    :items="getStatus"
                    item-text="item"
                    item-value="valeur"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Forfait-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('contract.Flat_rate') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('contract.Flat_rate')"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.forfait_id"
                    :items="flatRate"
                    item-text="item"
                    item-value="valeur"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Fournisseur-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('contract.Provider') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('contract.Provider')"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.fournisseur"
                    :items="provider"
                    item-text="item"
                    item-value="valeur"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Crédit actif-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('contract.Active_credit') }}
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Note"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.credit_actif"
                    disabled
                    type="number"
                    :background-color="getCreditActifBackground"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Note-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                Note*
              </v-col>

              <v-col cols="8">
                <v-textarea
                  v-model="submitForm.note"
                  append-icon="mdi-comment"
                />
              </v-col>
            </v-row>

            <!--  Mot (Champs requis)-->
            <v-col
              cols="2"
            >
              <div class="body-2 font-weight-light">
                *{{ $t('Required_Fields') }}
              </div>
            </v-col>

            <div class="pa-3 text-center">
              <!--  Bouton annuler-->
              <v-btn
                color="error"
                @click="cancel"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <!--  Bouton submit-->
              <v-btn
                color="success"
                class="ml-3"
                @click="handleSubmit(submit)"
              >
                {{ $t('Validate') }}
              </v-btn>
            </div>
          </base-material-card>
        </form>
      </validation-observer>
    </v-col>
  </v-row>
</template>
<script>
  // Service
  import ContratsService from '@/services/01Cell/ContratsService'
  import ClientsService from '@/services/01Cell/ClientsService'
  import MessagesService from '@/services/01Cell/MessagesService'
  import MailerService from '@/services/MailerService'
  // Mixins
  import { ContractEdit } from '@/mixins/Contract/ContractEdit'
  import { ordersUbrRules } from '@/mixins/Client/Order/ordersUbrRules'
  // Other
  import { mapGetters } from 'vuex'
  import Swal from 'sweetalert2'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { fr, enUS } from 'date-fns/esm/locale'
  window.dateFnsLocales = {
    fr,
    en: enUS,
  }
  export default {
    name: 'ClientContratCreateEdit',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [ContractEdit, ordersUbrRules],
    props: {
      createEditInfo: Object,
      color: String,
    },
    data () {
      return {
        language: localStorage.getItem('APP_LANG_KEY') || 'fr',
        test: null,
        name: '',
        submitForm: {},
        oldsubmitFormType: null,
        expandIndex: null,
        contratId: null,
        client: {
          entries: [],
          isLoading: false,
          search: null,
        },
        compteUQAM: '57012',
      }
    },

    computed: {
      getType () {
        const type = this.type
        if (this.submitForm.type) {
          // Si le mode de paiment est UBR seulement, on désactive
          if (this.parameterModePaiement === '1') {
            // On disabled la carte de crédit
            type[1].disabled = true
          }
        }
        return type
      },
      getTypeMessage () {
        if (this.submitForm.type && this.submitForm.Client) {
          // Si le mode de paiment contient un paiement automatique seulement et que l'utilisateur n'a pas de carte de crédit, on ne peut pas afficher le paiement par carte de crédit
          if (this.parameterModePaiement === '2' && !this.submitForm.Client.Inscription_paiements.length) {
            return this.$i18n.t('contract.Message_credit_card_not_found')
          }
        }
        return null
      },
      ...mapGetters('parameters', {
        parameterLongueurCodeFacturation: 'getLongueurCodeFacturation',
        parameterModePaiement: 'getModePaiement',
      }),
      getCreditActifBackground () {
        if (!this.submitForm.credit_actif) return null
        if (parseInt(this.submitForm.credit_actif) === 0) return null
        if (this.submitForm.credit_actif > 0) return 'green lighten-4'
        return 'red lighten-4'
      },
      checkDisabledStatus () {
        // Pour éviter les erreurs
        if (this.submitForm.Factures) {
          // Si il y a une facture de catégorie 2, quelle n'est pas payé et que le statut est 7 (suspendu), l'utilisateur ne peut pas changer le statut tant que la facture n'est pas payé
          return this.submitForm.Factures.some(item => item.categorie === 2 && !item.paiementId && this.submitForm.statut === 7)
        }
        return false
      },
    },

    watch: {
      'client.search': {
        // This will let Vue know to look inside the array
        deep: true,
        // We have to move our method to a handler field
        async handler () {
          if (!this.client.search) return
          if (this.client.search.length < 3) return
          // Items have already been requested
          if (this.client.isLoading) return

          this.client.isLoading = true

          try {
            const clients = await ClientsService.search(this.client.search, false)
            this.client.entries = clients.map(entry => {
              // L'object 'firstNameName' c'est pour l'affichage des nom dans le v-autocomplete
              entry.firstNameName = `[ ${entry.username} ] ${entry.prenom} ${entry.nom}`
              // console.log(Object.assign({}, entry, { Description }))
              return entry
            })
            this.client.isLoading = false
          } catch (error) {
            console.log(error)
          }
        },
      },
    },

    methods: {
      resetBillingcode (type) {
        // On avertie qu'un courriel est envoyé au client pour le changement de mode de paiement
        Swal.fire({
          icon: 'warning',
          title: 'Attention',
          html: this.$i18n.t('contract.swal.text.Replace_type_message'),
          heightAuto: false, // Put this each time. Prevents the html footer from being higher
        })
        // Si type Carte de crédit et que il n'a pas de carte de crédit dans le contrat, on en mets une si le mode de paiement est automatique seulement 'this.parameterModePaiement === '2'
        this.submitForm.paiementAutomatique = false
        if (type === 2 && !this.submitForm.InscriptionPaiementId && this.submitForm.Client) {
          if (this.submitForm.Client.Inscription_paiements.length && this.parameterModePaiement === '2') {
            this.submitForm.paiementAutomatique = true
            this.submitForm.InscriptionPaiementId = this.submitForm.Client.Inscription_paiements[0].id
          }
        }
      },
      async getContract (contratId, expandIndex) {
        // Expand index (Pour refermer après le submit)
        this.expandIndex = expandIndex

        // Stop Spinner
        this.$loading.show()
        try {
          this.contratId = contratId
          // Récupération du client
          this.submitForm = await ContratsService.view(contratId)
          this.oldsubmitForm = JSON.parse(JSON.stringify(this.submitForm))
          this.oldsubmitFormType = this.oldsubmitForm.type

          // Pour le client, on mets le Prénom et le nom pour le champs client
          if (this.submitForm.Client) {
            // Pour le client, on mets le Prénom et le nom pour le champs client
            this.submitForm.Client.firstNameName = `[ ${this.submitForm.Client.username} ] ${this.submitForm.Client.prenom} ${this.submitForm.Client.nom}`
            // On le mets dans les entrée du client
            this.client.entries.push(this.submitForm.Client)
          }
          // On regarde si il y a une facture de catégorie 2
          this.getContractStatus()
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      getContractStatus () {
        // On regarde si il y a une facture de catégorie 2.
        const findFacture = this.submitForm.Factures.find(item => item.categorie === 2 && !item.paiementId)
        // Si il y a une facture de catégorie 2, il faut mettre le statut 7 (suspendu) si il ne l'est pas déjà
        if (findFacture && this.submitForm.statut !== 7) {
          // On mets à jour dans la DB 'Contrats'
          ContratsService.update({ statut: 7 }, this.submitForm.id)
          this.submitForm.statut = 7
        }
      },
      submit () {
        // Si c'est un contrat en problème d'intégrité, on le mets actif et on envoie un courriel
        let sendMail = false
        if (this.submitForm.statut === 6) {
          this.submitForm.statut = 1
          sendMail = true
        }

        // Il faut mettre à jour le id du client
        // Si l'utilisateur a changé de client pour le contrat, je lui demande si il est sûr
        if (this.submitForm.ClientId !== this.submitForm.Client.id) {
          Swal.fire({
            icon: 'warning',
            title: this.$i18n.t('contract.swal.title.Change_customer'),
            text: this.$i18n.t('contract.swal.text.Change_customer'),
            showDenyButton: true,
            confirmButtonText: this.$i18n.t('Validate'),
            denyButtonText: this.$i18n.t('Cancel_changes'),
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.submitForm.ClientId = this.submitForm.Client.id
              // Voir le composant 'Contrat'
              this.$emit('create-update-contract', this.submitForm, this.expandIndex, true)
              // Envoie d'un courriel à l'utilisateur
              if (sendMail) this.sendEmailCreditCard()
              // Si l'utilisateur à changer de type (UBR, carte de crédit). On envoie un courriel
              this.sendEmailChangeType()
            } else if (result.isDenied) {
              Swal.fire(this.$i18n.t('Change_canceled'), '', 'info')
              // Reset du contrat
              this.getContract(this.contratId, this.expandIndex)
            }
          })
        } else {
          this.submitForm.ClientId = this.submitForm.Client.id
          // Voir le composant 'Contrat'
          this.$emit('create-update-contract', this.submitForm, this.expandIndex, false)
          // Envoie d'un courriel à l'utilisateur
          if (sendMail) this.sendEmailCreditCard()
          // Si l'utilisateur à changer de type (UBR, carte de crédit). On envoie un courriel
          this.sendEmailChangeType()
        }
      },
      cancel () {
        this.$emit('cancel-contract', this.expandIndex)
      },
      async sendEmailCreditCard () {
        // Si type 2 (Paiement carte de crédit) et Pas de carte de crédit et paiement automatique seulement
        if (this.submitForm.type === 2 && !this.submitForm.InscriptionPaiementId && this.parameterModePaiement === '2') {
          try {
            // Envoie du courriel pour dire au client qu'il doit entré une carte de crédit pour son contrat
            // Récupération des messages de changement de date
            const message = await MessagesService.replace(31, this.submitForm.Client.langue)

            // Envoie du courriel
            MailerService.sendMail({
              to: this.submitForm.Client.email,
              subject: message.subject,
              html: message.body,
            })
          } catch (error) {
            console.log(error)
          }
        }
      },
      async sendEmailChangeType () {
        if (this.oldsubmitFormType && this.submitForm.type) {
          // Si le type à changer, on envoie un courriel
          if (this.oldsubmitFormType !== this.submitForm.type) {
            // Si par UBR
            if (this.submitForm.type === 1) {
              Swal.fire({
                icon: 'warning',
                title: this.$i18n.t('contract.swal.title.Change_type'),
                text: this.$i18n.t('contract.swal.text.Change_type'),
                showCancelButton: true,
                showDenyButton: this.$company === 'uqam',
                confirmButtonText: this.$i18n.t('contract.swal.buton_text.Standard_message'),
                denyButtonText: this.$i18n.t('contract.swal.buton_text.No_funds_message'),
                cancelButtonText: this.$i18n.t('contract.swal.buton_text.Do_not_send_message'),
              }).then(async (result) => {
                try {
                  if (result.isConfirmed) {
                    this.sendEmailType(37)
                  } else if (result.isDenied) {
                    this.sendEmailType(36)
                  }
                } catch (error) {
                  console.log(error)
                }
              })
            } else if (this.submitForm.type === 2) {
              // Par carte de crédit
              this.sendEmailType(38)
            }
          }
        }
      },
      async sendEmailType (messageNo) {
        try {
          const message = await MessagesService.replace(messageNo, this.submitForm.Client.langue)
          const messageReplace = message.body.replace('[|CODE_FACTURATION|]', this.submitForm.code_facturation).replace('[|NO|]', `(${this.submitForm.no.slice(0, 3)}) ${this.submitForm.no.slice(3, 6)}-${this.submitForm.no.slice(6)}`)

          // Envoie du courriel
          MailerService.sendMail({
            to: this.submitForm.Client.email,
            subject: message.subject,
            html: messageReplace,
          })
        } catch (error) {
          console.log(error)
        }
      },
    },
  }
</script>
