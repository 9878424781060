<template>
  <v-row>
    <v-col cols="12">
      <base-material-card
        :color="contractType === 1 ? 'success' : 'primary'"
        :title="$t('invoices.Monthly_invoices')"
        icon="mdi-currency-usd"
        inline
        class="px-5 py-3"
      >
        <!--  Table pour les Factures Mesuelles (categorie 1)-->
        <v-data-table
          :headers="tableHeaders"
          :items="factureMensuelle"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :expanded.sync="expanded"
          item-key="id"
          @page-count="pageCount = $event"
        >
          <!--  ACTIONS-->
          <!--  eslint-disable-next-line vue/valid-v-slot-->
          <template #item.edit="{ item }">
            <!--  View-->
            <v-btn
              class="ml-2"
              fab
              dark
              x-small
              color="white"
              :title="$t('View')"
              @click="viewItem(item)"
            >
              <v-icon>{{ getButtonIcon(item.id) }}</v-icon>
            </v-btn>
          </template>
          <!--  DETAIL-->
          <!--  eslint-disable-next-line vue/valid-v-slot-->
          <template #item.detail="{ item }">
            <span class="primary--text">{{ `[ ${item.periode} ${item.fournisseur.toUpperCase()} ]` }}</span>
            <!--  Id de la facture-->
            <span> {{ `id: ${item.id}` }}</span>
            <!--  Pour corporatif 2 seulement et autre-->
            <template v-if="item.type === 2">
              <!--  Si Paiement est null, il doit payer la facture-->
              <v-btn
                v-if="checkPaiment(item)"
                color="pink accent-4"
                x-small
                class="ma-2"
                @click="payerFacture(item)"
              >
                <v-icon
                  left
                  dark
                  color="yellow accent-3"
                >
                  mdi-alert
                </v-icon>
                {{ $t('invoices.To_pay') }}
              </v-btn>

              <!--  Si facture est payé-->
              <v-btn
                v-if="item.paiementId"
                color="white"
                x-small
                class="ma-2"
                @click="openDialogPaiementFacture(item.id)"
              >
                {{ $t('contract.Bill') }}
                <v-icon
                  right
                  dark
                >
                  mdi-receipt
                </v-icon>
              </v-btn>

              <!-- Si message d'erreur dans la facture-->
              <template v-if="item.paiement_message">
                <span
                  class="red--text ml-3"
                >
                  {{ item.paiement_message }}
                </span>
              </template>
            </template>

            <!--  Lot-->
            <!--  Pour corporatif 1 seulement-->
            <template v-if="item.type === 1 && item.lot">
              [ Lot: {{ item.lot }} ]
            </template>
          </template>
          <!--  Total-->
          <!--  eslint-disable-next-line vue/valid-v-slot-->
          <template #item.total="{ item }">
            <span>{{ item.montant_total.toFixed(2) }}$</span>
          </template>
          <!--  OPTIONS-->
          <!--  eslint-disable-next-line vue/valid-v-slot-->
          <template #item.options="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <!--  Détail de la facture-->
          <template #expanded-item="{ headers, item }">
            <facture-mensuelle-details
              :headers="headers"
              :item="item"
            />
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          />
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <!-- <v-text-field
                  :value="itemsPerPage"
                  :label="$t('table.Items_per_page')"
                  type="number"
                  min="-1"
                  max="15"
                  @input="itemsPerPage = parseInt($event, 10)"
                /> -->
              <v-select
                v-model="itemsPerPage"
                :items="itemsPage"
                :label="$t('table.Bills_per_page')"
              />
            </v-col>
          </v-row>
        </div>
      </base-material-card>

      <!--  Dialog reçu-->
      <v-dialog
        v-model="dialogPaiement"
        max-width="1000"
      >
        <v-card>
          <!--  Print-->
          <v-icon
            class="mt-5"
            right
            dark
            large
            color="primary"
            @click="print"
          >
            mdi-printer
          </v-icon>
          <v-card-title>
            <v-icon
              aria-label="Close"
              @click="dialogPaiement = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="body-1 text-center">
            <v-row>
              <v-col
                cols="12"
              >
                <div :id="printMe">
                  <div v-html="recuFormat" />
                </div>
              </v-col>
            </v-row>

            <v-btn
              class="mt-2 ml-4"
              color="info"
              depressed
              default
              rounded
              @click="dialogPaiement = false"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>

    <!--  Payer une une facture manuelle GlobalPayments-->
    <v-dialog
      v-model="paymentGlobalPaymentsDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="paymentGlobalPaymentsDialog = !paymentGlobalPaymentsDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <global-payments-paiement
            :paiement-facture="paiementFacture"
            @payer-facture-success="payerFactureSuccess"
            @payer-facture-error="payerFactureError"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Payer une une facture manuelle Paysafe-->
    <v-dialog
      v-model="paysafePaymentsDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="paysafePaymentsDialog = !paysafePaymentsDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <paysafe
            :paiement-facture="paiementFacture"
            @payer-facture-success="payerFactureSuccess"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  // Component
  import FactureMensuelleDetails from '@/components/Client/Facture/FactureMensuelleDetails'
  import GlobalPaymentsPaiement from '@/components/Client/Facture/FactureManuelPaymentDialog/GlobalPayments'
  import Paysafe from '@/components/Client/Facture/FactureManuelPaymentDialog/Paysafe'
  // Mixins
  import { Payment } from '@/mixins/Payment/Payment'
  import { FacturePaiement } from '@/mixins/Facture/FacturePaiement'

  export default {
    name: 'FactureComponent',
    components: {
      FactureMensuelleDetails,
      GlobalPaymentsPaiement,
      Paysafe,
    },
    mixins: [Payment, FacturePaiement],
    props: {
      factures: Array,
      parameterFournisseurPaiement: String,
      parameterModePaiement: String,
      contractType: Number,
    },
    data () {
      return {
        paimentUni: process.env.VUE_APP_PAIEMENT_UNI,
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        itemsPage: [5, 10, 25, 50, 100],
        expanded: [],
        singleExpand: false,
        tableHeaders: [],
        factureHeaders: [
          { text: '', value: 'edit', sortable: false, class: 'text-h4 primary--text', width: '40px' },
          {
            text: this.$i18n.t('Detail'),
            align: 'start',
            sortable: false,
            value: 'detail',
            class: 'text-h4 primary--text',
          },
          { text: '$ Total', align: 'end', value: 'total', sortable: false, class: 'text-h4 primary--text' },
        ],
        factureHeadersOperation: [
          { text: '', value: 'edit', sortable: false, class: 'text-h4 primary--text', width: '120px' },
          {
            text: this.$i18n.t('Detail'),
            align: 'start',
            sortable: false,
            value: 'detail',
            class: 'text-h4 primary--text',
          },
          { text: '$ Total', align: 'end', value: 'total', sortable: false, class: 'text-h4 primary--text' },
          { text: 'Options', align: 'end', value: 'options', sortable: false, class: 'text-h4 primary--text' },
        ],
        callsDetail: [],
        paymentGlobalPaymentsDialog: false,
        paysafePaymentsDialog: false,
        printMe: null,
      }
    },

    computed: {
      factureMensuelle () {
        if (this.$route.name === 'Dashboard') {
          // Factures Mensuelles est la catégorie 1 (mensuelle) et le statut est 1 (Facture émise) statut est 2 (En erreur par token (automatique))
          return this.factures.filter(item => item.categorie === 1 && item.statut === 1).sort((a, b) => (a.periode < b.periode) ? 1 : -1)
        }

        // Admin
        // Factures Mensuelles est la catégorie 1 (mensuelle)
        return this.factures.filter(item => item.categorie === 1).sort((a, b) => (a.periode < b.periode) ? 1 : -1)
      },
    },

    created () {
      // Pas les opérations
      if (this.$route.name === 'Dashboard') {
        this.tableHeaders = this.factureHeaders
      } else {
        // Pour les opérations
        this.tableHeaders = this.factureHeadersOperation
      }
    },

    // async mounted () {
    //   try {
    //     console.log(this.factures)
    //     // this.factures = this.contratId
    //     // this.factures = contrat.Factures
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    methods: {
      checkPaiment (facture) {
        // // Si le paiement automatique seulement, le bouton n'apparaît
        // if (this.parameterModePaiement === '2') return false

        // // Si il y a un un InscriptionPaiementId (Carte de crédit associé) c'est un paiement automatique. On affiche pas le bouton
        // if (facture.InscriptionPaiementId) return false

        // On affiche le bouton si le if est vrai
        if (facture.categorie === 1 && facture.montant_total > 0 && !facture.paiementId) return true
        return false
      },
      getButtonIcon (id) {
        if (this.expanded.some(item => item.id === id)) {
          return 'mdi-eye-off-outline'
        }
        return 'mdi-eye-outline'
      },
      getFactureColSize (item) {
        // 4 6 12
        let col = 1
        if (item.appels.minutes_jour) col++
        if (item.data.length) col++
        return 12 / col
      },
      viewItem (value) {
        const index = this.expanded.indexOf(value)
        if (index === -1) {
          this.expanded.push(value)
        } else {
          this.expanded.splice(index, 1)
        }
      },
      payerFactureUpdate () {

      },
    },
  }
</script>

<style scoped>
  .text-bold {
    font-weight: bold;
  }

  .pointer {
    cursor: pointer;
  }
</style>
